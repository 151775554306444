
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IAudienceUserHeader, IQuestion } from '@/utils/types'
import { ACTION_APP_USER, ACTION_AUDIENCE, ACTION_QUESTION } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Formater } from '@/utils/formater'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading, TestModeSelector },
  directives: { maska },
})
export default class QAReport extends mixins(BaseFormMixin) {
  modalVisible = false
  loading = true
  columns: IAudienceUserHeader[] = []
  rows = []
  isTestMode = false
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  totalAudienceUsers = 0
  countActiveUsers = 0
  countBlockedUsers = 0

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get questions(): IQuestion[] {
    return this.$store.getters.questions
  }

  get displayColumns() {
    return this.columns.filter((item) => {
      if (item.is_display) {
        return true
      }
    })
  }

  @Watch('isTestMode')
  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.loading = true
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
    })

    if (result) {
      // eslint-disable-next-line
      this.totalAudienceUsers = result['total_audience_users']
      // eslint-disable-next-line
      this.countActiveUsers = result['count_active_users']
      // eslint-disable-next-line
      this.countBlockedUsers = result['count_blocked_users']
    }

    console.log(result, 'result')

    await this.loadTableHeader()
    // get initial data from server (1st page)
    this.$refs.tableRef.requestServerInteraction()
    this.loading = false
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async loadTableHeader() {
    this.columns = [
      {
        _id: 'is_active',
        label: this.$t('label.audience_user.is_active'),
        field: 'is_active',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'user_id',
        label: this.$t('label.audience_user.user_id'),
        field: 'user_id',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'display_name',
        label: this.$t('label.audience_user.display_name'),
        field: 'display_name',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'source_campaign_list',
        label: this.$t('label.audience_user.source_campaign_list'),
        field: 'source_campaign_list',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'source_list',
        label: this.$t('label.audience_user.source_list'),
        field: 'source_list',
        is_display: true,
        align: 'center',
      },
    ]
    for (const q of this.questions) {
      this.columns.push({
        _id: q._id,
        label: 'Q ' + q.title,
        field: q._id,
        is_display: false,
        align: 'center',
      })
    }
  }

  truncate(text, length, clamp) {
    clamp = clamp || '...'
    const node = document.createElement('div')
    node.innerHTML = text
    const content = node.textContent
    if (content) {
      return content.length > length ? content.slice(0, length) + clamp : content
    }

    return text
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loading = true
    this.pagination.rowsNumber = this.totalAudienceUsers
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceUsers : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending, page)
    this.rows.splice(0, this.rows.length, ...returnedData)
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending
    for (const user of this.rows) {
      Object.keys(user).forEach((element) => {
        const col = this.columns.find((item) => {
          if (item._id === element) {
            return true
          }
        })
        if (col) {
          col.is_display = true
        }
      })
    }

    this.loading = false
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending, page) {
    const items = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEM_DETAILS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        page: page,
        per_page: count,
        sort_by: sortBy,
        descending: descending,
      },
    })

    // TODO
    const resp = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        start_row: startRow,
        rows_number: count,
        page: page,
        per_page: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })

    console.log(resp, 'app users')

    return items
  }
}
